import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButtons, IonBackButton, IonCardContent } from '@ionic/react';
import { useEffect } from 'react';


const PrivacyPolicy: React.FC = () => {
    useEffect(() => {
        if (window && window.dataLayer && window.dataLayer.length > 0) {
            window.dataLayer.push({
                event: 'page_view',
                page: {
                    title: 'privacy-policy'
                }
            });
        }
    }, []);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/"></IonBackButton>
                    </IonButtons>
                    <IonTitle>Privacy Policy</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <IonCardContent>

                    <h1>Privacy Policy for sfcc-doc.ni18.in</h1>

                    <p>sfcc-doc.ni18.in is an unofficial Salesforce Commerce Cloud documentation web app that is designed for educational purposes. We are committed to protecting your privacy and the security of your personal information.</p>

                    <h2>What information do we collect?</h2>

                    <p>We collect the following information from our users:</p>

                    <ul>
                        <li>IP address</li>
                        <li>Browser type and version</li>
                        <li>Operating system</li>
                        <li>Date and time of visit</li>
                        <li>Pages visited</li>
                        <li>Searches performed</li>
                    </ul>

                    <p>We also use Google Tag Manager (GTM) and Google Analytics (GA) to collect additional information about our users' behavior on our website. This information may include:</p>

                    <ul>
                        <li>Clickstream data</li>
                        <li>Demographic data</li>
                        <li>Interests</li>
                    </ul>

                    <h2>How do we use your information?</h2>

                    <p>We use the information we collect to:</p>

                    <ul>
                        <li>Improve our website and the user experience</li>
                        <li>Understand how our users are interacting with our website</li>
                        <li>Troubleshoot any problems with our website</li>
                        <li>Provide you with personalized content and recommendations</li>
                        <li>Comply with applicable laws and regulations</li>
                    </ul>

                    <h2>How do we share your information?</h2>

                    <p>We do not share your personal information with any third parties, except as required by law or as part of our contract with Google for the use of GTM and GA.</p>

                    <h2>How do we protect your information?</h2>

                    <p>We take a number of steps to protect your personal information from unauthorized access, use, or disclosure. These steps include:</p>

                    <ul>
                        <li>Using encryption to protect your data in transit and at rest</li>
                        <li>Implementing security measures on our servers and networks</li>
                        <li>Limiting access to your personal information to authorized personnel</li>
                    </ul>

                    <h2>Your choices</h2>

                    <p>You have the following choices regarding your personal information:</p>

                    <ul>
                        <li>You can opt out of the collection of your personal information by Google Analytics by visiting the Google Analytics Opt-out Browser Add-on page.</li>
                        <li>You can clear your browser cookies at any time to remove any tracking information that has been stored on your computer.</li>
                    </ul>

                    <h2>Contact us</h2>

                    <p>If you have any questions about this privacy policy, please contact us at [email protected]</p>

                    <h2>Updates to this privacy policy</h2>

                    <p>We may update this privacy policy from time to time. If we make any changes, we will post the updated privacy policy on our website.</p>
                </IonCardContent>
            </IonContent>
        </IonPage>
    );
};

export default PrivacyPolicy;
