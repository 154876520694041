import React, { useState } from 'react'
export const PreferenceContext = React.createContext();
export default function PreferenceProvider(props) {
    let [passData, setPassData] = useState({});//pass  data from one page to other 
    return (
        <PreferenceContext.Provider value={{ passData, setPassData }}>
            {props.children}
        </PreferenceContext.Provider>
    )
}
