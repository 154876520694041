import React, { useEffect, useState } from "react";

import "./ViewArticle.css";
import { useParams, useHistory } from "react-router-dom";
import { } from "react-router-dom";

import {
  IonButtons,
  IonHeader,
  IonContent,
  IonToolbar,
  IonButton,
  IonIcon,
} from "@ionic/react";
import { home } from "ionicons/icons";

export default function Exam() {
  let [article, setArticle] = useState(<div>Loading....</div>);
  const { articleId } = useParams();
  const history = useHistory();
  const handleGoHome = () => {
    history.replace("/");
  };
  useEffect(() => {
    let url = articleId.replaceAll("*", "/").substring(1).trim();
    setArticle(<iframe className="i-frame" src={url}></iframe>);
  }, []);

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={handleGoHome}>
              <IonIcon slot="icon-only" icon={home}></IonIcon>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent class="ion-padding">{article}</IonContent>
    </>
  );
}
