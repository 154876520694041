import { Redirect, Route } from "react-router-dom";
import { IonApp, IonRouterOutlet, setupIonicReact } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import Home from "./pages/Home";
import Settings from "./pages/Settings";
import About from "./pages/static/About";
import PrivacyPolicy from "./pages/static/PrivacyPolicy";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import "./App.css";
import StaticDataProvider from "./context/StaticDataProvider";
import PreferenceProvider from "./context/PreferenceProvider";
import { useState } from "react";
import ViewArticle from "./pages/ViewArticle";

setupIonicReact();

const App: React.FC = () => {
  let [displayBanner, setDisplayBanner] = useState(false);
  return (
    <PreferenceProvider adsProps={{ displayBanner, setDisplayBanner }}>
      <StaticDataProvider>
        <IonApp className={displayBanner ? "load-banner" : "hide-banner"}>
          <IonReactRouter>
            <Route path="/doc/:articleId" exact={true}>
              <ViewArticle />
            </Route>
            <IonRouterOutlet>
              <Route exact path="/home">
                <Home />
              </Route>
              <Route path="/settings">
                <Settings />
              </Route>
              <Route path="/about">
                <About />
              </Route>
              <Route path="/privacy-policy">
                <PrivacyPolicy />
              </Route>
              <Route exact path="/">
                <Redirect to="/home" />
              </Route>
            </IonRouterOutlet>
          </IonReactRouter>
        </IonApp>
      </StaticDataProvider>
    </PreferenceProvider>
  );
};

export default App;
