import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonSearchbar,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import "./Home.css";
import { APP_NAME, GIT_HUB_URL, STOREFRONT_URL } from "../constants";
import { useContext, useEffect, useState } from "react";
import { ellipsisHorizontal, ellipsisVertical } from "ionicons/icons";
import { StaticDataContext } from "../context/StaticDataProvider";
declare global {
  interface Window {
    dataLayer: Record<string, any>[];
  }
}
const Home: React.FC = () => {
  let { index, pageMeta, setShowLoading } = useContext(StaticDataContext);
  const [searchResults, setSearchResults] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [displayContent, setDisplayContent] = useState(<div></div>);

  useEffect(() => {
    if (window && window.dataLayer && window.dataLayer.length > 0) {
      window.dataLayer.push({
        event: 'page_view',
        page: {
          title: 'home'
        }
      });
    }
  }, []);

  function openLink(url: any) {
    window.open(url, '_system');
  }
  const handleKeyPress = (e: React.KeyboardEvent<HTMLIonSearchbarElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSearch();
    }
  };
  const handleInput = (ev: Event) => {
    const target = ev.target as HTMLIonSearchbarElement;
    if (target) setSearchQuery(target.value!.toLowerCase());
  };

  const handleSearch = () => {
    setShowLoading(true);
    if (window && window.dataLayer && window.dataLayer.length > 0) {
      window.dataLayer.push({
        event: 'view_search_results',
        search_term: searchQuery
      });
    }
    const results = index.search(searchQuery);
    setSearchResults(
      results
        .filter((fResult: any) => {
          return !fResult.ref.includes("upcoming");
        })
        .map((result: any) => {
          return pageMeta[result.ref];
        })
    );
    setShowLoading(false);
  };

  useEffect(() => {
    let docList = searchResults.map((result: any) => {
      return (
        <IonItem
          onClick={() => {

            if (window && window.dataLayer && window.dataLayer.length > 0) {
              window.dataLayer.push({
                event: 'page_view',
                page: {
                  title: result.title
                }
              });
            }

          }}
          routerLink={"/doc/" + result.url.replaceAll("/", "*")}
          key={result.url}
          color="light"
        >
          <IonLabel class="ion-text-wrap">{result.title}</IonLabel>
          {result.url.includes("upcoming") ? (
            <IonButton color="medium" slot="end" disabled>
              upcomming
            </IonButton>
          ) : (
            <></>
          )}
        </IonItem>
      );
    });
    if (docList.length) {
      setDisplayContent(<IonList>{docList}</IonList>);
    } else {
      if (searchQuery.trim().length > 0) {
        setDisplayContent(
          <div className="no-result">
            <IonCardSubtitle>No result found</IonCardSubtitle>
          </div>
        );
      } else {
        setDisplayContent(
          <>
            <IonCardContent>
              Utilize the search bar to explore sfcc topics.
            </IonCardContent>

            <IonCard color="light">
              <IonCardHeader>
                <IonCardTitle>Welcome to SFCC Doc</IonCardTitle>
                <p>
                  SFCC-Doc.ni18.in is your go-to destination for unofficial Salesforce Commerce Cloud documentation. Designed exclusively for educational purposes, our platform provides clear, comprehensive resources and expert insights to help you master SFCC, whether you're a beginner or an advanced user. Explore our tutorials, guides, and community interaction to enhance your SFCC knowledge.                </p>
              </IonCardHeader>
            </IonCard>

            <IonCardContent>
              <IonButton
                color="light"
                routerLink={"/doc/*b2c-dev-doc*docs*current*scriptapi*html*api*classList.html"}
              >All Classes
              </IonButton>
              <IonButton
                color="light" aria-label="Sfcc doc storefront url" onClick={(e) => openLink(STOREFRONT_URL)} >Storefront</IonButton>
              <IonButton color="light" aria-label="sfcc github repo url" onClick={(e) => openLink(GIT_HUB_URL)}>GitHub</IonButton>
            </IonCardContent>

            <IonCardContent>
              <small>
                Disclaimer:
                This resource is unofficial and not endorsed by SFCC (Salesforce Commerce Cloud). It is intended for informational purposes only and should not be considered official documentation. For official and accurate information, please consult SFCC's official documentation and resources.
              </small>
            </IonCardContent>
          </>
        );
      }
    }
  }, [searchResults]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>{APP_NAME}</IonTitle>
          <IonButtons slot="end">
            <IonButton aria-label="Sfcc doc info" routerLink="/settings">
              <IonIcon slot="icon-only" ios={ellipsisHorizontal} md={ellipsisVertical}></IonIcon>
            </IonButton>
          </IonButtons>
        </IonToolbar>
        <IonToolbar>
          <div className="search-container">
            <IonSearchbar
              debounce={1000}
              onIonInput={(ev) => handleInput(ev)}
              onKeyPress={handleKeyPress}
              class="custom-search"
            ></IonSearchbar>
            <IonButton color="light" onClick={handleSearch}>
              Search
            </IonButton>
          </div>
        </IonToolbar>
      </IonHeader>
      <IonContent>{displayContent}</IonContent>
    </IonPage>
  );
};

export default Home;
