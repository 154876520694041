import { IonCard, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonItem, IonButtons, IonBackButton } from '@ionic/react';
import './Settings.css';
import { useEffect } from 'react';

const Settings: React.FC = () => {
  useEffect(() => {
    if (window && window.dataLayer && window.dataLayer.length > 0) {
      window.dataLayer.push({
        event: 'page_view',
        page: {
          title: 'setting'
        }
      });
    }
  }, []);


  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/"></IonBackButton>
          </IonButtons>
          <IonTitle>Setting</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonCard>
          <IonItem aria-label="Sfcc doc info" routerLink="/privacy-policy">
            Privacy Policy
          </IonItem>
          <IonItem aria-label="Sfcc doc info" routerLink="/about">
            About Us
          </IonItem>
        </IonCard>
      </IonContent>
    </IonPage>
  );
};

export default Settings;
