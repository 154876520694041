import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButtons, IonBackButton, IonCardContent } from '@ionic/react';
import { useEffect } from 'react';


const About: React.FC = () => {
    useEffect(() => {
        if (window && window.dataLayer && window.dataLayer.length > 0) {
            window.dataLayer.push({
                event: 'page_view',
                page: {
                    title: 'about'
                }
            });
        }
    }, []);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/"></IonBackButton>
                    </IonButtons>
                    <IonTitle>About</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <IonCardContent>
                    <h1>About SFCC Doc</h1>

                    <p>SFCC Doc is an unofficial Salesforce Commerce Cloud documentation web app that is designed for educational purposes. It was created by a group of Salesforce Commerce Cloud enthusiasts who wanted to create a more user-friendly and accessible way to learn about the platform.</p>
                    <p>SFCC Doc is completely free to use and does not require any login or registration. It covers a wide range of topics, including:</p>

                    <ul>
                        <li>Getting started with Salesforce Commerce Cloud</li>
                        <li>Administering Salesforce Commerce Cloud</li>
                        <li>Developing for Salesforce Commerce Cloud</li>
                        <li>Using Salesforce Commerce Cloud features</li>
                        <li>Troubleshooting Salesforce Commerce Cloud issues</li>
                    </ul>

                    <p>SFCC Doc is still under development, but it is already being used by thousands of people around the world to learn about Salesforce Commerce Cloud. We are committed to making SFCC Doc the best possible resource for anyone who wants to learn about this powerful platform.</p>

                    <h2>Why use SFCC Doc?</h2>

                    <p>There are many reasons why you should use SFCC Doc to learn about Salesforce Commerce Cloud:</p>

                    <ul>
                        <li>It is completely free to use.</li>
                        <li>It does not require any login or registration.</li>
                        <li>It is easy to use and navigate.</li>
                        <li>It covers a wide range of topics.</li>
                        <li>It is written by experts in Salesforce Commerce Cloud.</li>
                        <li>It is constantly being updated with new content.</li>
                    </ul>

                    <h2>How to use SFCC Doc</h2>

                    <p>To use SFCC Doc, simply visit the website and enter a search term in the search bar. SFCC Doc will return a list of relevant articles and tutorials. You can also browse through the different categories of topics to find articles on specific subjects.</p>

                    <h2>Disclaimer</h2>

                    <p>SFCC Doc is an unofficial Salesforce Commerce Cloud documentation web app. It is not affiliated with Salesforce in any way. Salesforce Commerce Cloud is a trademark of Salesforce.com, inc.</p>
                </IonCardContent>
            </IonContent>
        </IonPage>
    );
};

export default About;
