import React, { useEffect, useState } from 'react'
import lunr from 'lunr';
import { IonLoading } from '@ionic/react';

export const StaticDataContext = React.createContext();

export default function StaticDataProvider(props) {
    const [index, setIndex] = useState(null);
    const [pageMeta, setPageMeta] = useState([]);
    const [showLoading, setShowLoading] = useState(false);

    useEffect(() => {
        setShowLoading(true);
        const fetchData = async () => {
            console.log("F:Start");
            const response = await fetch('filteredSearch.json');
            const tempData = await response.json();
            const searchData = Object.values(tempData)
            console.log("F:End");

            console.log("P:Start");

            const idx = lunr(function () {
                this.field('title', { boost: 10 });
                this.field('content');
                this.ref('url');
                this.metadataWhitelist = ['position'];

                searchData.forEach(function (page) {
                    if (page.url && page.title && page.content) {
                        this.add(page);
                    }
                }, this);
            });
            console.log("P:End");
            setPageMeta(tempData);
            setIndex(idx);
            setShowLoading(false);

        };
        fetchData();
    }, []);

    return (
        <StaticDataContext.Provider value={{ index, pageMeta, showLoading, setShowLoading }}>
            {props.children}
            <IonLoading
                isOpen={showLoading}
                onDidDismiss={() => setShowLoading(false)}
                message={'Loading...'}
                duration={5000}
            />
        </StaticDataContext.Provider>
    )
}